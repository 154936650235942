import React from 'react';
import Home from '../components/Home';
import Box from '@mui/material/Box';
import ScrollIcon from '../components/ScrollIcon';



const HomeSection = () => {
  return (
	<section className='p-8 mt-8'>
		<Home />
		
	</section>
    
  );
};

export default HomeSection;
