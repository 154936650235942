import React from 'react';
import ProjectCard from '../components/ProjectCard';
import img_project1 from '../images/proj/cookie-project.gif';
import img_project2 from '../images/proj/proj2-intrusion.png';
import img_project3 from '../images/proj/proj3-hand-digit.jpg';


const projects = [
  {
    title: 'Web Cookie',
    description: 'Description of project one.',
    image: `${img_project1}`,
    url: 'https://web-cookie-assimilate.netlify.app/',
  },
  {
    title: 'Intrusion Detection',
    description: 'Description of project two.',
    image: `${img_project2}`,
    url: 'https://github.com/YouniqDexterous/Intrusion-Detection-System-Using-Pyspark',
  },
  {
    title: 'Hand-digit Detection',
    description: 'Description of project three.',
    image: `${img_project3}`,
    url: 'https://github.com/YouniqDexterous/Hand-Written-Digit-Recognition',

  },
];

const ProjectsSection = () => {
  return (
    <section id='proj-section' className="p-8  mt-8">
      <h2 className="text-4xl font-bold text-slate-200 mb-8">Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </section>
  );
};

export default ProjectsSection;
