import React, { useState } from 'react';
import { IconButton, Tooltip, Grow } from '@mui/material';
import ChatIcon from '@mui/icons-material/StreamRounded';
import CloseIcon from '@mui/icons-material/Close';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { styled } from '@mui/material/styles';

const FloatingContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 80, // Adjusted to make space above the menu icon
  right: 16,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

}));

const FloatingMenu = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton 
        className='text-xl'
        color="primary"
        style={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          //backgroundColor: '#1F2937', // Dark background for visibility
          color: '#FFFFFF',

        }}
        onClick={handleClick}
      >
        {open ? <CloseIcon style={{ fontSize: '50px' }} /> : <ChatIcon style={{ fontSize: '50px' }} className='motion-safe:animate-spin-slow'/>}
      </IconButton>

      <Grow in={open}>
        <FloatingContainer>
          
          <Tooltip title="GitHub" arrow>
            <IconButton
              component="a"
              href="https://github.com/youniqdexterous"
              target="_blank"
              color="primary"
              style={{ margin: '8px' }}
            >
              <GitHubIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="LinkedIn" arrow>
            <IconButton
              component="a"
              href="https://www.linkedin.com/in/yxk"
              target="_blank"
              color="primary"
              style={{ margin: '8px' }}
            >
              <LinkedInIcon />
            </IconButton>
          </Tooltip>
          {/* Add more icons as needed */}
        </FloatingContainer>
      </Grow>
    </div>
  );
};

export default FloatingMenu;
