import { Box, Card, CardContent, Typography } from '@mui/material';
import '../styles/TechStackCard.css';

import React from 'react';
import java_img from '../images/tech/java.png';
import spring_img from '../images/tech/spring-boot.png';
import html_img from '../images/tech/html.png';
import react_img from '../images/tech/react.png';
import typescript_img from '../images/tech/typescript.png';
import javascript_img from '../images/tech/javascript.png';

const techStack = [
  { name: 'Java', image: java_img },
  { name: 'Spring Boot', image: spring_img },
  { name: 'React', image: react_img },
  { name: 'HTML', image: html_img },
  { name: 'TypeScript', image: typescript_img },
  { name: 'JavaScript', image: javascript_img },
];

const TechStackCard = () => {
  return (
    <Card className='tech-card' sx={{ maxWidth: 900, marginTop: 4, backgroundColor: '#1e1e1e', color: 'white', boxShadow: '0 4px 8px rgba(255, 105, 97, 0.5)' }}>
      <CardContent>
        <Typography variant="h5" component="div" className='text-center font-sans' gutterBottom>
          Technologies I Work With
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: 2 }}>
          {techStack.map((tech, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10px' }}>
              <img src={tech.image} alt={tech.name} style={{ width: 50, height: 50, objectFit: 'contain' }} />
              <Typography variant="body1" component="div" sx={{ marginTop: 1 }}>
                {tech.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TechStackCard;
