import React from 'react';
import '../styles/AnimatedText.css';

const AnimateText = () => {
  const text = 'youniq dexterous';
  const letters = text.split('');

  return (
    <div className="animated-text">
      {letters.map((letter, index) => (
        <span key={index} className={`letter letter-${index}`}>
          {letter}
        </span>
      ))}
    </div>
  );
};

export default AnimateText;
