import React from 'react';
import Container from '@mui/material/Container';
import Navbar from './components/Navbar';
import HomeSection from './sections/HomeSection';
import ProjectsSection from './sections/ProjectsSection';
import FloatingContactIcon from './components/FloatingContactIcon';
import { Grid } from '@mui/material';

const App = () => {
  return (
    <div className='bg-slate-800'>
      <Container
        className="min-h-screen bg-slate-800 bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]"
      >
        <Grid container direction="column" className="min-h-screen overflow-auto">
          <Grid item>
            <Navbar />
          </Grid>
          <Grid item className="h-screen">
            <HomeSection />
          </Grid>
          <Grid item className="h-screen">
            <ProjectsSection />
          </Grid>
          <FloatingContactIcon />
        </Grid>
      </Container>
    </div>
  );
};

export default App;

