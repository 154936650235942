import {Box} from '@mui/material';
import '../styles/HomePage.css';
import React, {useEffect} from 'react';
import AnimateText from './AnimateText';
import ScrollIcon from './ScrollIcon';
import TechStackCard from './TechStackCard';


const Home = () => {
  useEffect(() => {
    const handleMouseMove = (e) => {
      const cursor = document.querySelector('.custom-cursor');
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);
  return (
    <Box className="flex flex-col items-center h-screen bg-[size:14px_24px] px-4 overflow-clip">
      <img
        src="favicon.ico"
        alt="Profile"
        className="w-20 h-20 rounded-full mb-4 shadow-lg bg-slate-800"
      />
      <div className='custom-cursor'></div>
      <AnimateText />
      <TechStackCard />
                
      <ScrollIcon targetId={'proj-section'}/>
      {/*</Box>*/}
      
    </Box>
  );
};

export default Home;
