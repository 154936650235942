import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// Styled Card component to ensure consistent layout
const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // Ensure card takes full height
  transition: 'box-shadow 0.3s ease-in-out',
  boxShadow: '0 4px 8px rgba(255, 105, 97, 0.5)', // Light red shadow
  '&:hover': {
    boxShadow: '0 8px 16px rgba(255, 105, 97, 0.7)', // Brighter shadow on hover
  },
}));

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
	height: 140, // Fixed height for image
	objectFit: 'contain', // Ensure image covers area without distortion
	width: '100%',
  }));
  


const ProjectCard = ({ title, description, image, url }) => {
  return (
    <StyledCard>
      <CardMediaStyled className='bg-slate-900'
        component="img"
        image={image}
        alt={title}

      />
      <CardContent>
        <Typography className='font-sans font-bold hover:animate-pulse cursor-pointer' gutterBottom variant="h5" 
		component="a"
		href={url}
		target="_blank" >
          {title}
        </Typography>
        <Typography variant="body2" className='font-sans' color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default ProjectCard;
