import React from 'react';
import '../styles/HomePage.css';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

// Define styles using MUI's styled API
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#1F2937', // Tailwind's gray-900 color
}));

//const StyledButton = styled(Button)(({ theme }) => ({
//  color: '#FFFFFF', // White color for buttons
//}));

const Navbar = () => {
  return (
    <StyledAppBar position="static">
      <Toolbar className="flex justify-between">
        <Typography variant="h6" className='font-serif animated-name' component="div" style={{ fontWeight: 'bold', color: '#FFFFFF' }}>
              Fullstack Developer
        </Typography>
        <div>
          <InfoIcon className='animate-bounce' />
        </div>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
