import React from 'react';
import '../styles/ScrollIcon.css';

const ScrollIcon = ({targetId}) => {
	const scrollToSection = () => {
		const target = document.getElementById(targetId);
		if (target) {
			window.scrollTo({
				top: target.offsetTop,
				behavior: 'smooth',
			});
		}
	};


  return (
	<div className='scroll-icon' onClick={scrollToSection}>
		<div className='mouse'>
			<div className='mouse-button'></div>
		</div>
		<div className='arrow'></div>
	</div>
  )
}

export default ScrollIcon;
